@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:40px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Abel', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:32px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:32px;
	font-family: 'Abel', sans-serif;
}
h3 {
	font-size:28px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Abel', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:24px;
	font-family: 'Abel', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Abel', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body
{
	font-family: 'Lato', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'Lato', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#000;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg, .welcome-msg *, .userCode, .userName, .userSwitch a {
	color: #000 !important;
    font-weight:600;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Lato', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #000;
	border-width: 6px 5px 0;
    opacity: 1;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 0;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #fff;
	font-size: 20px;
	font-family: 'Lato', sans-serif;
}
.links > li > a {
    color: #000;
	font-size: 16px;
    font-weight:600;
}
.nav-regular li.level0 > a {
    text-transform: inherit;
    font-weight: 600;
        line-height: 40px !important;
}
.links > li > a:hover {
	background-color: #ffba00;
    color: #000!important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 18px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 18px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #FFBA00 !important;
	padding: 0;
	border-radius: 10px;
	max-height: 40px;
	font-size: 18px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #D9D9D9 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 10px 0;
	font-size: 14px;
    font-weight: 600;
	letter-spacing: 2px;
	text-transform: capitalize;
    color:#000;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #000;
}
.add-cart-wrapper button span span {
	width: 60px;
	max-width: 60px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #FFBA00;
	border-radius: 10px;
	padding: 0;
    font-weight:600;
}
button, .button, .button a, button a {
	font-family: 'Lato', sans-serif;
	font-size: 18px;
}
button.button span {
	font-size: 18px;
}
button.button span span {
	padding: 0 24px; 
    height:40px; 
    line-height: 40px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #D9D9D9 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #000;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
    display:none;
}


/************************** MICS **************************/

@media only screen and (max-width:767px) {
     h1{
         font-size:32px !important;
    }
}
 .container {
     width:98% !important;
     max-width:98% !important;
}
@media only screen and (min-width:1800px) {
     .container {
         width:94% !important;
         max-width:94% !important;
    }
}
.fa {
     font-style:normal !important;
}
a {
     color:#000;
}
/*Footer:starts*/
.footer-primary-container {
     display:none;
}
.footer-top-container {
     margin:0;
}
#footer .logo-column img {
     width:50%;
}
@media only screen and (min-width:960px) {
     .footer-top-container .section.clearer{
         display:flex;
    }
    .footer-top-container .section.clearer > div{
         margin:auto;
    }
}
 #footer .links-footer {
     color:#fff;
     display:flex;
     justify-content: end;
}
#footer .links-footer > a{
     margin:auto 25px auto 0;
     font-size:20px;
     font-family:'Abel', sans-serif !important;
     border-right:2px solid #fff;
     padding-right:25px;
     font-weight:700;
     color:#fff;
}
#footer .links-footer > a:last-child {
     border:none;
}
@media only screen and (min-width:1600px) {
     #footer .links-column {
         width:23%;
    }
     #footer .cutoff-content {
         width:48%;
    }
}
#scroll-to-top {
    bottom: 10px !important;
    right: 20px !important;
}

/*Tablet*/
@media only screen and (min-width:768px) and (max-width:959px) {
     #footer .logo-column {
         width:30% !important;
    }
     #footer .cutoff-content {
         width:60% !important;
    }
     #footer .links-column {
         width:60% !important;
         margin-top: 20px;
    }
}
@media only screen and (max-width:1500px) {
    .footer-top-container h1 {
         font-size:30px !important;
    }
}
@media only screen and (max-width:959px) {
    .footer-top-container h1 {
         font-size:20px !important;
    }
}

/*Mobile*/
@media only screen and (max-width:767px) {
     .main-container {
         padding-bottom:70px;
    }
     .footer-top-container {
         display:none;
    }
     .footer-primary-container {
         display:block;
         padding:0 !important;
         position:fixed;
         left:0;
         bottom:0;
    }
     .footer-primary-container .section.clearer {
         width:100%;
         padding:10px 0 !important 
    }
     .sticky-footer-icons {
         display:flex;
    }
     .sticky-footer-icons > div {
         margin:0 auto;
         text-align:center 
    }
     .sticky-footer-icons img {
         width:42%;
    }
     .sticky-footer-icons p {
         color:#fff;
         font-size:10px;
         margin-bottom:0;
         font-weight:700;
         line-height:14px;
    }
}
.footer-bottom-container.section-container {
     display:none;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top{
     background-color:#000;
}
/*category pages*/
@media only screen and (min-width:960px) {
     .cat-title {
         text-align:center;
    }
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:16px !important;
     width:auto !important;
}
.product-search-container {
     margin-left:0;
}
.product-search-container .sidebar-category .block-title span, .product-search-container .category-title h1{
     padding:0 !important;
}
/*Pagination*/
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pagination .module-category-misc {
     text-align:right;
}
.pager li a, .pager strong{
     font-size:16px;
}
.pager .pages li a{
     background-color:#D9d9d9;
}
.pager .pages li.current {
     background-color:#fff;
}
.pager .pages li a:hover {
     background-color:#D9d9d9;
}
.product-search-container .block-title {
     border-bottom:none;
}
.product-search-container .block-title span{
     font-size:28px !important;
     border-bottom:none;
     font-family:'Abel', sans-serif !important;
}
.product-search-container .block-content .accordion-style1 li a {
     font-size:20px !important;
     border-bottom:none;
     font-family:'Abel', sans-serif !important;
}
.category-products-grid .item {
     border:none;
}
.item .module-category-product-listing span, .module-category-product-listing li, .module-category-product-listing li strong, .item .module-category-product-listing .product-name a {
     font-family:'Lato', sans-serif !important;
     font-size:20px !important;
     font-weight:600;
     color:#000;
}
.item .module-category-product-listing h2.product-name {
     min-height:50px;
}
.item .module-category-product-listing .module-category-product-listing__productImage {
     border:1px solid #FFBA00;
}
.item .module-category-product-listing .product-image-wrapper {
     height:auto !important;
}
.item .module-category-product-listing span.item-code {
     font-size:18px !important;
     font-weight:400;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:7px;
}
.item .module-category-product-listing .product-shop-inner, .item .module-category-product-listing .price-box{
     text-align:left;
}
 .item .module-category-product-listing .add-cart-wrapper {
     display:flex;
}
 .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
     margin:auto 10px;
}
 .item .module-category-product-listing .add-cart-wrapper > button{
     margin:auto;
     margin-left:10px;
}
.item .module-category-product-listing .add-cart-wrapper input.qty {
     height:34px;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12 {
         overflow-y:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.btn-cart span span {
         padding:0 12px !important;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper {
         margin-left:0;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
.item .module-category-product-listing .qty-wrapper {
     display:flex;
}
.item .module-category-product-listing .qty-wrapper > * {
     margin:auto;
}
.item .module-category-product-listing .decrement-qty, .item .module-category-product-listing .increment-qty {
     padding:5px 7px;
     cursor:pointer;
}
.item .module-category-product-listing .decrement-qty {
     margin-left:0;
}
.item .module-category-product-listing .qty-wrapper .qty-box {
     border:none;
     font-size:20px;
}
.item .module-category-product-listing .qty-wrapper input.qty {
     width:2em!important;
}
.item .module-category-product-listing .qty-wrapper {
     border-radius:10px;
     border:3px solid #FFBA00;
}
@media only screen and (max-width:767px) {
     .item .module-category-product-listing .decrement-qty, .item .module-category-product-listing .increment-qty {
         padding:1px 3px;
    }
     .item .module-category-product-listing .add-cart-wrapper button span span {
         width:30px;
         max-width:30px;
         font-size:17px !important;
    }
     .item .module-category-product-listing .add-cart-wrapper input.qty {
         height:30px;
    }
     .container {
         padding-left:1%;
         padding-right:1%;
    }
}
/*Category pages:end*/
/*Header:starts*/
.top-header-container, .top-header-container .header-top-container, .mobile-header .header-top-container {
     background-color:#FFBA00;
     box-shadow:none;
}
/*User links*/
.top-header-container ul.links {
     display:flex;
     float:right;
}
.top-header-container ul.links > li.first{
     order:3;
}
.top-header-container ul.links > li:not(.first) {
     border-right:1px solid black;
}
.top-header-container .links>li, .top-header-container .links>li>a {
     line-height:1em !important;
}
.top-header-container .module-welcome, .top-header-container .module-switch-customer{
     float:right;
}
.top-header-container .module-switch-customer{
     max-width:290px;
     text-align:end;
}
.top-header-container .module-switch-customer .userCode{
     float:left;
}
.top-header-container .module-switch-customer .userName{
     max-width:220px;
     float:left;
}
.top-header-container .module-switch-customer .userSwitch{
     display:block;
}
.top-header-container p.welcome-msg {
     margin-bottom:0;
}
.top-header-container .feature-icon-hover {
     font-weight:600;
}
.top-header-container .welcome-txt, .top-header-container .logo-column{
     margin-top:10px;
}
.top-header-container .logo-column {
     margin-left:0;
}
.top-header-container .tagline {
     margin-top:20px;
}
.welcome-txt .item-user-links, .welcome-txt .item-switch-customer {
     margin-bottom:20px;
}
.welcome-txt .item-user-links {
     margin-top:15px;
}
/*Alignment to the right*/
.top-header-container ul.links > li.first a, .top-header-container .dropdown-toggle.cover>div{
     padding-right:0 !important;
}

 /*date picker*/
 .top-header-container .date-picker-col {
     text-align:center;
     margin-top:-36px;
     margin-bottom:10px;
}
.top-header-container .date-picker-title {
     font-size:18px;
     font-weight:600;
     margin-right:10px;
}
.top-header-container .date-picker-date input {
     border:1px solid black;
     border-radius:10px;
     font-size:16px;
     width:120px;
}
 /*Menu*/
 .desktop-category-column, .desktop-category-column #nav {
     background-color:#000;
}
 .desktop-category-column #nav {
     width:auto;
     float:right;
}
 .desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
 .desktop-category-column .grid-container {
     display:flex;
}
 .desktop-category-column .grid-container > *{
     margin:auto;
}
.desktop-category-column .grid-container > .search-column{
     margin-right:0;
}
@media only screen and (min-width:1200px) {
     .desktop-category-column #nav li.level0 {
         margin-left:20px;
    }
}
.desktop-category-column .nav-regular .nav-item.level0:hover>a>span{
     color:#fff !important;
}
#nav .nav-item.level0.parent:hover>a .caret {
     border-top-color:#fff;
}
 /*Search*/
 .desktop-category-column #search, .mobile-header #search {
     border-radius:20px;
}
 .desktop-category-column .form-search .input-text::placeholder, .mobile-header .form-search .input-text::placeholder {
     font-size:16px;
     color:#000;
}
 .desktop-category-column .form-search .input-text{
     height:30px;
     line-height:30px;
         padding-top: 3px;
}
 .desktop-category-column .module-search .form-search .button-search, .mobile-header .module-search .form-search .button-search{
     top:-3px !important;
}
.tagline h1 {
     font-size:36px;
}
@media only screen and (max-width:1535px) {
     .tagline h1 {
         font-size:34px;
    }
}
@media only screen and (max-width:1299px) {
     .tagline h1 {
         font-size:30px;
    }
}
 @media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
 @media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
 @media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
 @media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}

/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:200%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     #inline-search-results {
         width:200%;
         left:0;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:180%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
/*Tablet*/
@media only screen and (max-width:959px) {
     .top-header-container .logo img {
         width:90%;
    }
     .welcome-txt .item-user-links, .welcome-txt .item-switch-customer {
         margin-bottom:5px;
    }
     .top-header-container .module-switch-customer .userCode, .top-header-container .module-switch-customer .userSwitch {
         float:right;
    }
     .top-header-container .welcome-txt{
         margin-top:20px;
    }
     .tagline h1 {
         font-size:16px;
    }
     .top-header-container .welcome-txt .fa.fa-bars {
         margin-right:0;
         padding-right:0 !important;
    }
     .top-header-container .date-picker-col {
         margin-top:-36px;
    }
     .top-header-container .date-picker-title {
         font-size:14px;
    }
     .top-header-container, .desktop-category-column, .desktop-category-column .search-column, .top-header-container .welcome-txt, .top-header-container .welcome-txt .module-header-multi, .top-header-container .welcome-txt .item-min-cart, .mobile-header, .mobile-header .module-header-multi, .mobile-header .module-header-multi .item-min-cart{
         overflow:unset;
    }
     .desktop-category-column .grid-container {
         height:36px;
    }
     .mobile-header .header-top-container {
         background-color:#000;
         box-shadow:none;
    }
     .mobile-header .grid-container {
         display:flex;
    }
     .mobile-header .grid-container > *{
         margin:auto;
         width:48% !important;
    }
     .mobile-header .form-search .input-text {
         background-color:#e5e5e5;
    }
     .mobile-header .fa:not(.fa-search) {
         color:#fff !important;
         font-size:22px !important;
    }
     .mobile-header .fa-search {
         color:#000 !important;
    }
     .mobile-header .cart-total {
         background-color:#FFBA00;
         padding:0 7px;
         border-radius:50px;
         font-weight:700;
         position:absolute 
    }
     .mobile-header .feature-icon-hover .caret {
         display:none;
    }
     .mobile-header .fa-shopping-cart {
         padding:0.5em 0;
         left:0 !important;
    }
}
/*Header for Mobile*/
@media only screen and (max-width:767px){
     .mobile-header, .top-header-container{
         margin-bottom:0;
    }
     .top-header-container .grid-container {
         display:flex;
    }
     .top-header-container .grid-container > div {
         margin:auto;
    }
     .top-header-container .logo-column {
         max-width:100px;
    }
     .top-header-container .logo img {
         max-width:80px;
         width:80px;
    }
     .top-header-container .item-switch-customer span, .top-header-container .item-switch-customer a, .top-header-container .date-picker-date input, .top-header-container .date-picker-title, .mobile-header .cart-total, .mobile-header .form-search .input-text::placeholder {
         font-size:13px !important;
    }
     .top-header-container .module-switch-customer {
         text-align:center;
    }
     .top-header-container .module-switch-customer .userName {
         max-width:120px;
    }
     .top-header-container .item-switch-customer .userCode, .top-header-container .item-switch-customer .userName, .top-header-container .item-switch-customer .userSwitch {
         float:none;
         margin-left:0;
    }
     .top-header-container .date-picker-col {
         margin-top:0;
    }
     .top-header-container .date-picker-date input.input-text {
         height:25px;
         width:90px !important;
    }
     .mobile-header #search.input-text {
         height:30px;
         line-height:30px;
    }
     .mobile-header .module-header-multi > .item-left{
         float:left !important;
    }
     .mobile-header .module-header-multi > .item-right {
         float:right !important;
    }
     .mobile-header .form-search .button-search .fa {
         color:#000 !important;
    }
     .mobile-header .logo img {
         width:60%;
    }
     .mobile-header .feature-icon-hover .empty {
         display:none;
    }
     .mobile-header .cart-total {
         line-height:18px;
         padding:0 5px;
    }
     
}
 /*header:ends*/

